import styled from 'styled-components'
import Image from '../../design-system/image'
import Title from '../../design-system/title'

const StyledCoffee = styled.div`
  width: 70%;
  margin: auto;

  @media only screen and (max-width: 1250px) {
    width: 80%;

    h1 {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 930px) {
    width: 100%;
  }

  @media only screen and (max-width: 825px) {
    width: 60%;
    padding-top: 4rem;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 0px 4rem;

  p {
    text-align: justify;
  }

  @media only screen and (max-width: 825px) {
    grid-template-columns: 1fr;
  }
`

const CoffeeImage = styled(Image)`
  width: 100%;
`

const CoffeeControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media only screen and (max-width: 825px) {
    margin-bottom: 3rem;
  }
`

const StyledLink = styled.a`
  background-color: black;
  text-decoration: none;
  font-family: 'Amatic SC';
  color: white !important;
  padding: 0.8rem 1rem;
  font-size: 2rem;
`

export function Coffee() {
  return (
    <StyledCoffee id="coffee">
      <Content>
        <div>
          <Title fontSize="3rem">Na mlýnku</Title>
          <p>
            Skvělá výběrová káva od českých pražíren, kterou se snažíme obměňovat, abychom
            Vás dokázali více vtáhnout do kávového světa. Při výběru kávy do našeho mlýnku
            si dáváme záležet, proto u nás najdete tu nejlepší kávu z tuzemských pražíren,
            která nám padla do oka.
          </p>
          <CoffeeControls>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="184.133"
              height="68.001"
              viewBox="0 0 184.133 68.001"
            >
              <path d="M98.612 45.507c-4.494-4.493-1.325-12.582 4.73-18.637 6.245-6.244 14.239-9.318 18.731-4.825s1.373 12.535-4.824 18.731c-6.008 6.008-14.145 9.225-18.638 4.73m-.567.568c7.285 7.285 16.745 6.906 24.124-.473 7.332-7.332 7.711-16.792.474-24.125-7.285-7.189-16.793-6.859-24.125.474-7.379 7.379-7.71 16.887-.473 24.124"></path>
              <path d="M1.098 67.501l2.611-3.012V20.336l-2.611-3.008h9.5v4.079a13.115 13.115 0 0 1 10.033-4.749c9.031 0 14.518 6.354 14.518 16.057 0 11.506-7.965 18.729-16.859 18.729a15.072 15.072 0 0 1-7.691-2.205v15.25l2.605 3.012zm9.5-44.62v25.221a11.791 11.791 0 0 0 6.686 2.139c6.355 0 10.906-7.493 10.906-17.327 0-8.092-3.078-15.051-8.764-15.051a11.011 11.011 0 0 0-8.828 5.018zm141.227 14.346l21.74-8.3v-1.071c0-6.619-2.611-10.5-7.492-10.5a12.762 12.762 0 0 0-4.684.935l-6.689 11.1-1.139-8.895a24.438 24.438 0 0 1 12.709-4.014c9.236 0 14.25 5.151 14.25 13.38v17.322l2.611 3.479h-7.961l-1.605-6.421a12.446 12.446 0 0 1-11.307 7.092c-8.16-.058-11.437-6.346-10.431-14.107zm6.623-1.875c-1.275 7.427 1.469 14.183 6.756 14.183 3.811 0 6.955-2.743 8.361-6.822V29.871zM40.594 50.779l2.611-3.013V20.34l-2.611-3.012h9.5v30.438l2.605 3.013zm92.973-.106l2.605-3.007V3.513L133.567.501h9.5v47.165l2.611 3.007zm-49.67 0l-15.92-18.327v15.32l2.605 3.007H58.473l2.615-3.007V3.513L58.473.501h9.5v30.641l12.775-9.1-4.15-4.816H89.18L68.303 32.075l8.229 1.2 13.111 14.183 4.551 3.21z"></path>
              <title>Pikola</title>
            </svg>
            <StyledLink href="https://kafepikola.cz">zjistit více</StyledLink>
          </CoffeeControls>
        </div>
        <div>
          <CoffeeImage src={'dobroty/pikola.jpg'} />
        </div>
      </Content>
    </StyledCoffee>
  )
}
